import React from "react";
import { Helmet } from "react-helmet";
import favicon from "../images/favicon.ico";
import { useStaticQuery, graphql } from "gatsby";

const Head = props => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);
  return (
    <Helmet
      title={`${props.title} | ${data.site.siteMetadata.title}`}
      link={[{ rel: "shortcut icon", type: "ico", href: `${favicon}` }]}
      meta={[
        {
          name: "description",
          content:
            "The Secret Sits Pro Musica Ensembles is a nonprofit musical charity incorporated in the states of Georgia and New York. We are devoted to the mission that Better Music makes a Better World. ",
        },
      ]}
    />
  );
};

export default Head;
